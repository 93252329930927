import React, { useState, useEffect } from "react";
import NetworkManager from "@/outscal-commons-frontend/Managers/NetworkManager";
import UTMManager from "@/Logic/ClientLogic/Services/UTMManager";
import { toast } from "react-toastify";
import {
  CoursesLeadsFormData as coursesLeadsFormData,
  defaultPhoneNumberData,
  formFilledData,
  fsgdCourseDetails,
  redirectToCourseDetailsWithResumePopup,
} from "./LeadsForm.model";
import LeadsFormView from "./LeadsForm.view";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import FormFilledView from "./FormFilledView";
import { useRouter } from "next/router";
import useAuthActions from "@/store/actions/authAction";

const LeadsForm = ({
  paidCoursesIdAndLabels = [],
  showFtue = false,
  formGap,
  dropdownHeading = "Course you are interested in*",
  ctaText = "Register & Enroll Now",
  formHeading = "Reach out to us",
  showGoogleAuthCta = true,
}) => {
  const router = useRouter();
  const { user, getAndSetUserProfile, updateUserProfile } = useUserActions();
  const [defaultValues, setDefaultValues] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const { auth, setToken } = useAuthActions();

  useEffect(() => {
    if (typeof localStorage !== "undefined") {
      setSubmitted(localStorage.getItem("courseLeadSubmitted") === "true");
    }
  }, []);

  const populateFormData = async () => {
    let defaultObj = {};
    defaultObj.name = user?.userJobProfile?.name;
    defaultObj.email = user?.userDetails?.email;
    if (paidCoursesIdAndLabels.length > 0) {
      defaultObj.course_of_interest = fsgdCourseDetails;
      coursesLeadsFormData.inputs.course_of_interest.optionsList =
        paidCoursesIdAndLabels;
      coursesLeadsFormData.inputs.course_of_interest.heading = dropdownHeading;
    }
    defaultObj.phone_number = defaultPhoneNumberData;
    defaultObj.phone_number.number = user?.userJobProfile?.phone_number;
    setDefaultValues(defaultObj);
  };

  const handleRedirection = ({ showFtue, course_of_interest = "", hasToken }) => {
    if (showFtue && typeof localStorage !== "undefined") {
      localStorage.setItem("prev-url", "/courses");
      router.push("/welcome/ftue1");
    } else {
      router.push(
        `/course/${course_of_interest}${
          hasToken ? redirectToCourseDetailsWithResumePopup : ""
        }`
      );
    }
  };

  useEffect(() => {
    populateFormData();
  }, [user]);

  const onSubmit = async (e) => {
    let userData = JSON.parse(localStorage.getItem("userData") || "{}");
    const landingIntent = UTMManager.instance.landingIntent;
    const intent = UTMManager.instance.getCurrentIntent();
    const retargetingIntent = UTMManager.instance.sessionIntent;

    const resp = await NetworkManager.post(
      "course-leads/create",
      {
        name: e.name,
        email: e.email,
        phone_number: e.phone_number,
        intent: { landingIntent, intent, retargetingIntent },
        course_of_interest: e.course_of_interest?.id || "",
        utms: UTMManager.instance.lastUtm,
        authDetails: {
          email: userData?.email,
          name: userData?.name,
          userId: userData?._id,
        },
      },
      undefined,
      undefined,
      true
    );
    const hasToken = resp?.data?.user?.token;
    if (resp.error) {
      toast.error(resp.message);
    } else {
      toast.success("We will contact you soon.");
      if (typeof localStorage !== "undefined") {
        localStorage.setItem("courseLeadSubmitted", true);
      }
      setSubmitted(true);
      if (!auth?.token) {
        if (hasToken) {
          setToken(hasToken, resp?.data?.user);
          await getAndSetUserProfile();
        }
      } else {
        if (!user?.userJobProfile?.phone_number?.length) {
          await updateUserProfile();
        }
      }
      handleRedirection({
        showFtue,
        course_of_interest: e?.course_of_interest?.id,
        hasToken,
      });
    }
  };

  const updateCoursesLeadsFormData = (paidCoursesIdAndLabels) => {
    const updatedFormData = { ...coursesLeadsFormData };

    if (paidCoursesIdAndLabels.length < 1) {
      const { course_of_interest, ...remainingInputs } = updatedFormData.inputs;
      updatedFormData.inputs = remainingInputs;
    }

    return updatedFormData;
  };

  return !submitted ? (
    <LeadsFormView
      formName={coursesLeadsFormData?.name}
      formHeading={formHeading}
      formData={updateCoursesLeadsFormData(paidCoursesIdAndLabels)?.inputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      submitText={auth?.token ? "Connect With Us" : ctaText}
      hasAuthToken={auth?.token}
      paidCoursesIdAndLabels={paidCoursesIdAndLabels}
      formGap={formGap}
      showGoogleAuthCta={showGoogleAuthCta}
    />
  ) : (
    <FormFilledView
      heading={formFilledData?.heading}
      subHeading={formFilledData?.subHeading}
      ctaText={formFilledData?.ctaText}
      ctaLink={formFilledData?.ctaLink}
    />
  );
};

export default LeadsForm;
